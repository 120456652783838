import { useEffect, useState } from 'react'
import createImage from './utils/createImage'

const usePreloadImageBulk = (
  images: { id: string; url: string; width: number }[]
): { [id: string]: HTMLImageElement } => {
  const [imagesUpdated, setImagesUpdated] = useState<{
    [id: string]: HTMLImageElement
  }>({})

  useEffect(() => {
    images.forEach((imageObj) => {
      const { url, width } = imageObj
      const newImage = createImage(url, width)

      newImage.onload = () => {
        if (!imagesUpdated[imageObj.id]) {
          setImagesUpdated((prevState) => ({
            ...prevState,
            [imageObj.id]: newImage,
          }))
        }
      }
    })
  }, [images, imagesUpdated])

  return imagesUpdated
}

export default usePreloadImageBulk
