import { IconSVGProps } from '../types'

export const AddIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.33334 7.33331V3.33331H8.66668V7.33331H12.6667V8.66665H8.66668V12.6666H7.33334V8.66665H3.33334V7.33331H7.33334Z" />
  </svg>
)
