import { Text } from '@fable/components'
import { cx } from '@fable/theme'
import { Link } from 'react-router-dom'
import { LinksProps } from './types'

const Links: React.FC<LinksProps> = ({
  itemClass = '',
  itemsClass = '',
  linkClass = '',
  links = [],
  onSelectItem,
  wrapperClass = '',
}) => {
  const renderedLinks = links.map((item, index) => {
    const { image, title, link, newTab, isExternal, isVector } = item

    const renderValue = () => {
      if (isVector) {
        return image
      }

      const content = image ? <img src={image} alt={title} /> : title

      return (
        <Text tag="span" type="link" sizing="L" color="inherit">
          {content}
        </Text>
      )
    }

    const renderTarget = () => {
      return newTab ? '_blank' : '_self'
    }

    return (
      <li key={index} className={itemClass}>
        {!isExternal ? (
          <Link
            className={linkClass}
            target={renderTarget()}
            to={link}
            onClick={onSelectItem}
          >
            {renderValue()}
          </Link>
        ) : (
          <a
            className={linkClass}
            href={link}
            target={renderTarget()}
            rel="noopener noreferrer"
          >
            {renderValue()}
          </a>
        )}
      </li>
    )
  })

  return (
    <div className={cx(wrapperClass, 'fds-ui--links links')}>
      <ul className={cx(itemsClass, 'fds-ui--link-items')}>{renderedLinks}</ul>
    </div>
  )
}

export default Links
