import { useEffect } from 'react'

type AnyEvent = MouseEvent | TouchEvent

const useClickOutside = (
  ref: React.RefObject<Element>,
  handler: (event: AnyEvent) => void
): void => {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      if (event instanceof TouchEvent) {
        event.preventDefault()
      }

      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }
      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export default useClickOutside
