import { Hyperlink, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { Link } from 'react-router-dom'
import { HeaderNavItem } from './types'

export const HeaderSideMenuLink = ({ link }: { link: HeaderNavItem }) => {
  const { colors, motion } = useTheme()

  const content = (
    <Text tag="span" type="link" sizing="L" color="inherit">
      {link.title}
    </Text>
  )

  if (link.type === 'external') {
    return (
      <Hyperlink href={link.path} color={colors.blackSwan}>
        {content}
      </Hyperlink>
    )
  }

  return (
    <Link
      className={css`
        color: ${colors.blackSwan};
        ${motion.transition('opacity')};
        &:hover {
          opacity: 0.7;
        }
      `}
      to={link.path}
    >
      {content}
    </Link>
  )
}
