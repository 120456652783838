import { createContext, Dispatch, SetStateAction, useMemo } from 'react'
import { UseInfiniteQueryResult } from 'react-query'
import { Book, Club } from '@fable/types'
import { useDebounce } from '@fable/hooks'

import { useRef, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { get } from '@fable/api'
import { useErrorModal } from 'hooks'
import { analytics } from 'segment_analytics'
import { useSearchParams } from 'react-router-dom'

const useSearch = (allowFilters: boolean) => {
  const pageOffset = useRef(0)
  const hasNextPage = useRef(true)
  const [searchbarFocus, setSearchbarFocus] = useState(false)
  const [input, setInput] = useState('')
  const [error, setError] = useState(undefined)
  const [activeFilter, setActiveFilter] = useState<'book' | 'club' | ''>('')
  const availabilityFilter =
    '&store_availability=pre_launch,available,out_of_catalog'
  const [searchParams] = useSearchParams()
  const debouncedInput = useDebounce(input, 500) as string

  const filters = useRef<SearchFilterType[]>([
    { title: 'All', key: '' },
    { title: 'Books', key: 'book' },
    { title: 'Clubs', key: 'club' },
  ])

  const searchQuery = useInfiniteQuery(
    ['searchResults', debouncedInput, activeFilter],
    async ({ pageParam }) => {
      const res = await get(
        `/search?auto=${encodeURIComponent(
          debouncedInput
        )}${availabilityFilter}${
          activeFilter ? `&type=${activeFilter}` : ''
        }&limit=20&offset=${pageParam}&v2=${searchParams.get('v2') || 'false'}`
      )

      return res.data
    },
    {
      enabled: !!debouncedInput && !!debouncedInput.replace(/\s/g, '').length,
      onError: setError,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      // while keepPreviousData is true, content won't be cleared as you type
      // while false, previous data will appear before new search data populates (like when re-opening the searchbar)
      keepPreviousData: !!debouncedInput,
      getNextPageParam: () => pageOffset.current,
      onSuccess: (data) => {
        const lastPage = data.pages[data.pages.length - 1]
        const booksEmpty = !!lastPage && lastPage.response?.books?.length === 0
        const clubsEmpty = !!lastPage && lastPage.response?.clubs?.length === 0

        if (
          (activeFilter === 'book' && booksEmpty) ||
          (activeFilter === 'club' && clubsEmpty)
        ) {
          hasNextPage.current = false
        }
        analytics.events.searchQuery({
          type: activeFilter,
          query: debouncedInput,
        })
      },
    }
  )

  const resultsFlattened = useMemo(
    () =>
      searchQuery.data?.pages
        .map((page) => {
          const response = page?.response || {}
          const collections = Object.keys(response)
          const squashed = collections.flatMap((key) => response[key])

          // If the designs change and we need to separate results into categories then remove flatmap
          return squashed
        })
        .flatMap((page) => page),
    [searchQuery.data?.pages]
  )

  const getNextPage = () => {
    // react-query hasNextPage returns true when it should be false
    if (!hasNextPage.current) return

    pageOffset.current += 20
    searchQuery?.fetchNextPage()
  }

  useErrorModal(error)

  return {
    hasNextPage: hasNextPage.current,
    searchQuery,
    resultsFlattened,
    input,
    filters: allowFilters ? filters.current : undefined,
    activeFilter,
    allowFilters,
    searchbarFocus,
    setInput,
    setActiveFilter,
    getNextPage,
    setSearchbarFocus,
  }
}

export type SearchFilterKey = 'book' | 'club' | ''

export interface SearchFilterType {
  title: string
  key: SearchFilterKey
}

export const SearchContext = createContext<{
  /** @param {boolean} hasNextPage - Has another page to fetch */
  hasNextPage: boolean
  /** @param  {UseInfiniteQueryResult<Book[] | Club[], unknown> | null} searchQuery - The react-query useInfiniteQuery hook for search results */
  searchQuery?: UseInfiniteQueryResult<Book[] | Club[], unknown> | null
  /** @param  {Book[] | Club[]} resultsFlattened - Book and club results flattened to a single array */
  resultsFlattened?: Book[] | Club[]
  /** @param  {string} input - Input entered into the searchbar */
  input?: string
  /** @param  {SearchFilterType[]} filters - Set of filters available for search (read only) */
  filters?: SearchFilterType[]
  /** @param  {SearchFilterKey} activeFilter - The current active filter */
  activeFilter?: SearchFilterKey
  /** @param  {boolean} allowFilters - Will hide filters if false */
  allowFilters: boolean
  /** @param  {boolean} searchbarFocus - The value set by setSearchbarFocus */
  searchbarFocus: boolean
  /** @param  {(s: string) => void} setInput - Sets input for the searchbar. Search is performed automatically via react-query */
  setInput: (s: string) => void
  /** @param  {Dispatch<SetStateAction<SearchFilterKey>>} setActiveFilter - Sets the active filter for search results */
  setActiveFilter: Dispatch<SetStateAction<SearchFilterKey>>
  /** @param  {Dispatch<SetStateAction<boolean>>} setSearchbarFocus - Sets the value for searchbarFocus. This currently has one specific use case
   * in which the searchbar has to re-render at the top of the page when clicked. Remove if behavior is changed */
  setSearchbarFocus: Dispatch<SetStateAction<boolean>>
  /** @param  {() => void} getNextPage - Gets the next page of results by adding 20 to the page offset and refetching via react-query */
  getNextPage: () => void
}>({
  hasNextPage: true,
  searchQuery: undefined,
  resultsFlattened: undefined,
  input: undefined,
  filters: undefined,
  activeFilter: undefined,
  allowFilters: true,
  searchbarFocus: false,
  setInput: () => null,
  setActiveFilter: () => '',
  setSearchbarFocus: () => false,
  getNextPage: () => null,
})

SearchContext.displayName = 'SearchContext'

export const SearchContextProvider = ({
  allowFilters,
  children,
}: {
  allowFilters: boolean
  children: React.ReactNode | React.ReactNode[]
}) => (
  <SearchContext.Provider value={useSearch(allowFilters)}>
    {children}
  </SearchContext.Provider>
)
