export const ensureFableScripts = () => {
  if (!window.fableScripts) {
    window.fableScripts = {}
  }
}

export const registerFableScript = (
  name: string,
  fn: (...args: any[]) => void
) => {
  ensureFableScripts()
  window.fableScripts[name] = fn
}
