import { IconSVGProps } from '../types'

export const InfoSolidIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.0013 12.8334C3.77955 12.8334 1.16797 10.2218 1.16797 7.00008C1.16797 3.77833 3.77955 1.16675 7.0013 1.16675C10.2231 1.16675 12.8346 3.77833 12.8346 7.00008C12.8346 10.2218 10.2231 12.8334 7.0013 12.8334ZM6.41797 6.41675V9.91675H7.58464V6.41675H6.41797ZM6.41797 4.08341V5.25008H7.58464V4.08341H6.41797Z" />
  </svg>
)
