import { useCallback, useEffect } from 'react'
import { useTypedSelector } from './useTypedSelector'

interface Props {
  offset?: number
}

export const usePageHashScroll = ({ offset = 0 }: Props = {}) => {
  const { isVerifying } = useTypedSelector(({ auth }) => auth)

  const handleScroll = useCallback(() => {
    if (isVerifying) return
    const scrollToHash = () => {
      const hash = window.location.hash
      if (hash) {
        const element = document.getElementById(hash.substring(1))
        if (element) {
          const elementPosition =
            element.getBoundingClientRect().top + window.scrollY
          window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' })
        }
      }
    }

    const timeoutId = setTimeout(scrollToHash, 500)

    return () => clearTimeout(timeoutId)
  }, [offset, isVerifying])

  useEffect(() => {
    handleScroll()
  }, [handleScroll])
}
