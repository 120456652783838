import { IconSVGProps } from '../types'

export const LockOutlineIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <path d="M9.5 5H10C10.1326 5 10.2598 5.05268 10.3536 5.14645C10.4473 5.24021 10.5 5.36739 10.5 5.5V10.5C10.5 10.6326 10.4473 10.7598 10.3536 10.8536C10.2598 10.9473 10.1326 11 10 11H2C1.86739 11 1.74021 10.9473 1.64645 10.8536C1.55268 10.7598 1.5 10.6326 1.5 10.5V5.5C1.5 5.36739 1.55268 5.24021 1.64645 5.14645C1.74021 5.05268 1.86739 5 2 5H2.5V4.5C2.5 4.04037 2.59053 3.58525 2.76642 3.16061C2.94231 2.73597 3.20012 2.35013 3.52513 2.02513C3.85013 1.70012 4.23597 1.44231 4.66061 1.26642C5.08525 1.09053 5.54037 1 6 1C6.45963 1 6.91475 1.09053 7.33939 1.26642C7.76403 1.44231 8.14987 1.70012 8.47487 2.02513C8.79988 2.35013 9.05769 2.73597 9.23358 3.16061C9.40947 3.58525 9.5 4.04037 9.5 4.5V5ZM2.5 6V10H9.5V6H2.5ZM5.5 7H6.5V9H5.5V7ZM8.5 5V4.5C8.5 3.83696 8.23661 3.20107 7.76777 2.73223C7.29893 2.26339 6.66304 2 6 2C5.33696 2 4.70107 2.26339 4.23223 2.73223C3.76339 3.20107 3.5 3.83696 3.5 4.5V5H8.5Z" />
  </svg>
)
