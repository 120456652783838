import { Book } from './bookTypes'
import { Club } from './clubTypes'
import { FileObject } from './genericTypes'
import { Quote } from './quoteTypes'

export interface ProfileConnectionUser {
  id: string
  pic: string
  pic_resize: string
  display_name: string
  private_profile: boolean
  pronouns: string
  url: string
}

export interface ProfileConnection {
  user: ProfileConnectionUser
  started_at: string
  is_following: boolean
  follows_you: boolean
  follow_requested: null
}

export interface ProfileConnectionsMapping {
  count: number
  next: null
  previous: null
  results: ProfileConnection[]
}

export interface Capabilities {
  strong_password: boolean
  inactivity_timeout: boolean
  image_upload: boolean
}

export enum SubscriptionTier {
  fable_friends = 'fable_friends',
  basic = 'basic',
  none = 'none',
}

export interface User {
  free_trial_used?: boolean
  referral_id?: string
  bio?: string
  cart_uuid?: string
  display_name?: string
  email?: string
  id?: string
  phone?: string
  pic?: string
  pic_resize?: string
  pic_updated?: boolean
  private_profile?: boolean
  signed_up_at?: string
  subscription_tier?: SubscriptionTier
  username?: string
  website?: string
  pronouns?: string
  url?: string
  following_count?: number
  followers_count?: number
  is_following?: boolean
  follows_you?: boolean
  follow_requested?: boolean
  capabilities?: Capabilities
  tiktok_url?: string
  twitter_url?: string
  goodreads_url?: string
  instagram_url?: string
  linkedin_url?: string
  youtube_url?: string
  profile_image?: string
  is_stats_public?: boolean
  stats_summary_opt_in?: boolean
  badges?: UserBadgeType[]
  subscription?: UserSubscription
}

export interface UserSubscription {
  features: UserSubscriptionFeature[]
  entitlement: string
  product: string
  store: string
  purchased_at: string
  expiration_at?: string
  is_trial: boolean
}

export type UserSubscriptionFeature =
  | 'custom_icon'
  | 'fable_friends_badge'
  | 'streak_widget'
  | 'custom_goals'
  | 'advanced_stats'

export type UserBadgeType = 'fable_friends'

export interface EditProfileData {
  displayName?: string
  bio?: string
  profileImage?: FileObject
  privateProfile?: boolean
  pronouns?: string
  edited?: boolean
}

export type UserPostAuthor = Partial<ProfileConnectionUser>

export interface UserPostCounters {
  likes: number
  comments: number
}

export type UserPostContentType = 'userPost' | 'editorialPost'
export type UserPostSource = 'contentful' | 'fable'

export interface UserPostImageSize {
  width: number
  height: number
}

export interface UserPostLinks {
  comments: string
  mentions: string
  reactions: string
  share_url: string
}

export interface UserPost {
  id: string
  content_type: UserPostContentType
  source: UserPostSource
  external_id: string
  author: UserPostAuthor
  title: string
  body: string
  featured_image: string
  image: string | null
  image_size: UserPostImageSize | null
  club: Club | null
  quotes?: Quote[]
  books: Book[]
  contains_spoilers: boolean
  created_at: string
  counters: UserPostCounters
  user_liked: boolean
  activity_text: string
  links: UserPostLinks
}

export interface SmallUser {
  id: string
  pic: string
  pic_resize: string
  display_name: string
  pronouns: string
  instagram_url: string
  tiktok_url: string
  linkedin_url: string
  twitter_url: string
  youtube_url: string
  goodreads_url: string
  private_profile: boolean
  url: string
  bio: string
  title: string
  referral_id: string
  is_following: boolean
  follows_you: boolean
  follow_requested?: boolean
}

export interface ReferralUserExtra {
  clubs_joined: number
  reviews: number
  days_streak: number
  posts: number
}

export interface ReferralUser extends SmallUser {
  extra: ReferralUserExtra
}
